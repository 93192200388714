<template>
  <div>
    <Breadcrumbs />
    <section>
      <b-container>
        <b-row>
          <b-col col lg="12">
            <div v-if="product" class="product-review">
              <h3>
                Reviews over de
                <span> {{ product.name }}</span>
              </h3>
              <div>
                <b-link to="/add-review" class="btn btn-primary">
                  {{ $t("write_review_button") }}
                </b-link>
              </div>
              <div class="review-list">
                <div class="relevant-review-title">
                  Meest relevante beoordelingen
                </div>
                <div v-if="product.reviews.items.length == 0">
                  {{ $t("no_reviews") }}
                </div>
                <ProductReview
                  :review="rev"
                  v-for="(rev, index) of product.reviews.items"
                  :key="index"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
// import BaseLayout from "@/base/core/components/common/BaseLayout";
import Breadcrumbs from "@/base/core/components/common/Breadcrumbs";
import ProductReview from "@/base/core/components/products/product-detail/ProductReview";
export default {
  components: {
    // BaseLayout,
    Breadcrumbs,
    ProductReview,
  },
  data: () => ({}),
  computed: {
    product() {
      return this.$store.getters["product/getCurrentProduct"];
    },
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
    };
  },
};
</script>
