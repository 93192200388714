`<template>
  <div class="product-reviews">
    <div class="summary">{{ review.summary }}</div>
    <div class="d-flex">
      <ProductRating :stars="4" />
      <div class="rating-counter ml-2"><strong>4.0</strong></div>
    </div>
    <div class="nickname-date">{{ review.nickname }} - {{ reviewDate }}</div>
    <div class="review f-14">{{ review.text }}</div>
    <!-- <div v-for="(rat, idx) of review.ratings_breakdown" :key="idx">
              <ProductRating
                :title="rat.name"
                :stars="rat.value"
              ></ProductRating>
            </div> -->
  </div>
</template>

<script>
import ProductRating from "@/base/core/components/products/product-detail/ProductRating";
export default {
  name: "ProductReview",
  props: ["review"],
  components: {
    ProductRating,
  },
  computed: {
    reviewDate() {
      let reviewDate = new Date(this.review.created_at);
      let dd = reviewDate.getDate();
      let mm = reviewDate.getMonth() + 1;
      let yyyy = reviewDate.getFullYear();
      if (dd < 10) dd = "0" + dd;
      if (mm < 10) dd = "0" + mm;
      reviewDate = dd + "-" + mm + "-" + yyyy;
      return reviewDate;
    },
  },
};
</script>
